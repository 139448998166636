<template>
  <div>
    <a-button class="add-com-btn" @click="visible = true" type="primary">
      添加考车
    </a-button>
    <div class="ant-table-wrapper">
      <div
        class="ant-table ant-table-fixed-header ant-table-scroll-position-left ant-table-layout-fixed ant-table-default"
      >
        <div
          class="ant-table-body"
          style="overflowy: scroll; margin-right: -17px;"
        >
          <table class="have-max-width">
            <thead class="ant-table-thead">
              <tr>
                <th>编号</th>
                <th>车型</th>
                <th>状态</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody class="ant-table-tbody">
              <tr v-for="item in carList" :key="item.id">
                <td>{{ item.hotCode }}</td>
                <td>{{ item.licName }}</td>
                <td>
                  <a-switch
                    checked-children="正常"
                    un-checked-children="维修"
                    default-checked
                    :checked="item.statusId == 0 ? false : true"
                    @click="changeSta(item)"
                  />
                </td>
                <td>
                  <a-button @click="editCar(item)">编辑</a-button>
                </td>
              </tr>
              <tr v-if="!carList.length">
                <td colspan="3" class="no-data">暂无考车</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--    添加车辆-->
    <a-modal
      :title="edit ? '编辑考车' : '添加考车'"
      okText="确定"
      cancelText="取消"
      :visible="visible"
      @ok="addCarMethod"
      @cancel="visible = false"
    >
      <div class="addrole">
        <p class="flex">
          <label for="">
            编
            <span style="opacity: 0;">等等</span>
            号:
          </label>
          <a-input class="flex1" v-model="addCar.hotCode"></a-input>
        </p>
        <p class="flex">
          <label for="">
            车
            <span style="opacity: 0;">等等</span>
            型:
          </label>
          <a-select v-model="addCar.licName" class="flex1">
            <a-select-option value="C1">
              C1
            </a-select-option>
            <a-select-option value="C2">
              C2
            </a-select-option>
            <a-select-option value="A1">
              A1
            </a-select-option>
            <a-select-option value="A2">
              A2
            </a-select-option>
            <a-select-option value="A3">
              A3
            </a-select-option>
            <a-select-option value="D">
              D
            </a-select-option>
            <a-select-option value="E">
              E
            </a-select-option>
            <a-select-option value="F">
              F
            </a-select-option>
          </a-select>
        </p>

        <p class="flex">
          <label for="">
            状
            <span style="opacity: 0;">等等</span>
            态:
          </label>
          <span class="flex1" style="padding-left: 10px;">
            <a-switch
              checked-children="正常"
              un-checked-children="维修"
              default-checked
              v-model="addCar.status"
            />
          </span>
        </p>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  components: {},
  data() {
    return {
      edit: false,
      visible: false,
      addCar: {
        hotCode: '',
        status: true,
        licName: '',
        examSpotId: this.$store.state.curSpotId,
      },
      carList: [],
    }
  },
  methods: {
    moment,
    changeSta(item) {
      let carStatus = item.statusId == 0 ? 1 : 0
      this.$post(this.$url.编辑考车, {
        examSpotId: this.$store.state.curSpotId,
        statusId: carStatus,
        id: item.id,
      }).then(() => {
        this.getCarList()
      })
    },
    //获取教练列表
    choiceExam() {
      this.examList.forEach((item) => {
        if (this.addCar.examSpotId == item.id) {
          this.addCar.subjectId = item.subjectId
        }
      })
    },
    addCarMethod() {
      if (this.addCar.status) {
        this.addCar.statusId = 1
      } else {
        this.addCar.statusId = 0
      }

      if (this.edit) {
        this.editCarMethod()
        return
      }
      this.$post(this.$url.添加考车, this.addCar).then(() => {
        this.$tips('操作成功', '考试车辆添加成功！', 'success')
        this.visible = false
        this.getCarList()
      })
    },
    editCar(item) {
      this.visible = true
      this.edit = true
      this.addCar.licName = item.licName
      this.addCar.id = item.id
      this.addCar.hotCode = item.hotCode
      this.addCar.status = item.statusId == 1 ? true : false
    },
    editCarMethod() {
      this.$post(this.$url.考车编辑, this.addCar).then(() => {
        this.$tips('操作成功', '考试车辆编辑成功！', 'success')
        this.getCarList()
        this.edit = false
        this.visible = false
        this.addCar.hotCode = ''
        this.addCar.status = true
      })
    },
    getCarList() {
      this.$post(this.$url.考车列表, {
        examSpotId: this.$store.state.curSpotId,
      }).then((data) => {
        this.carList = data.data.list
        this.total = data.data.total
      })
    },
  },
  mounted() {
    this.getCarList()
  },
}
</script>
<style scoped>
.select-self {
  width: 300px;
}
.flex {
  line-height: 25px;
}
p.flex {
  margin-bottom: 15px;
}
.flex .flex1 {
  margin-left: 5px;
}

.addrole label {
  display: inline-block;
  width: 68px;
  text-align: right;
  margin-right: 10px;
  box-sizing: border-box;
  line-height: 30px;
}
.ant-input {
  padding: 4px 0px 4px 10px;
  box-sizing: border-box;
}
</style>
